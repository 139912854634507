export const productFeedManager = {
  app: {
    name: '客制化产品目录',
  },
  tableHeader: {
    channelName: '产品目录名称',
    lang: '语言',
    updateTime: '上次更新时间',
  },
  emptyList: {
    title: '客制化您的产品目录内容，让您轻松管理！',
    description: '设定并新增产品目录的规则，你可在此管理你的产品目录',
  },
  title: {
    channelEntry: '新增产品目录',
  },
  tips: {
    chanelLimit: '已达数量上限',
  },
  tooltip: {
    copyLink: '复制链接',
  },
  popover: {
    productFeedManagerFaq: {
      title: '了解更多',
      content: '各渠道的客制化产品目录更新时间不尽相同，更多功能说明请见 FAQ',
      function: '功能说明：',
      link: 'https://support.shoplineapp.com/hc/zh-tw/articles/35139419848217',
    },
  },
  channel: {
    title_GOOGLE: 'Google',
    title_CRITEO: 'Criteo',
  },
  channelListPopup: {
    title: '选择渠道',
    description: '当前每个渠道只能新增一个客制化产品目录，请选择您要新增的渠道',
    description_GOOGLE:
      '支援 Pinterest 及 TikTok 产品目录，并支援部分 Martech、导购渠道使用 (如 Omnichat、圈圈(Influenxio)、Insider、禾多、Rosetta 等)',
    description_CRITEO: '联播网再行销最强利器',
  },
  form: {
    title: '商品上传规则',
    description: '使用不同条件建立产品目录的生成规则',
    valid: {
      required: '{{fieldName}} 是必须的',
    },
    fields: {
      title: {
        title: '产品目录名称',
        placeholder: '最多输入 50 字',
      },
      withOutOfStockProduct: {
        title: '选择商品销售状态',
        options: {
          all: '商品有无库存皆上传',
          part: '仅上传有库存或可销售的商品',
        },
      },
      withPreorderProduct: {
        title: '加入预购商品',
      },
      categoryIds: {
        title: '指定商品分类 (选填)',
        placeholder: '请选择',
        selectedNum: '已选（{{num}}）',
      },
      uniqueIds: {
        title: '使用系统独立产品编号或商品货号？',
        options: {
          number: '使用商品货号',
          customNumber: '使用系统独立产品编号',
        },
      },
      mainProductImageSelect: {
        title: '选择商品主要图片 ',
        options: {
          first: '第一张',
          second: '第二张',
          last: '最后一张',
        },
      },
      variantProductImageSelect: {
        title: '选择商品规格图档',
        options: {
          main: '商品主要图片',
          sku: '商品规格图片',
        },
      },
      withMpn: {
        title: '是否加入制造编号 (MPN)、商品条码编号 (Barcode)?',
      },
      locale: {
        title: '选择目标语言',
        options: {
          zhHant: '繁體中文',
          zhHans: '简体中文',
          en: 'English',
        },
      },
    },
    footer: {
      save: '储存',
      description: '最后一步！确认以上内容后点击储存按钮以完成',
    },
    leavePopup: {
      title: '尚未储存变更',
      description: '如果选择离开，所做的异动将会流失，建议储存后再离开。',
    },
  },
  productOverview: {
    title: '商品概览',
    description:
      '查看符合左侧条件将上传的商品资料，注意，此处仅列出下次更新客制化产品目录将包含的商品，并不代表产品目录的即时资料',
    entry: '查看商品',
    popup: {
      title: '查看商品',
      footer: {
        total: '包含 {{n}} 件商品',
      },
    },
    table: {
      column: {
        name: '商品名称',
        variation: '商品规格',
      },
    },
    empty: {
      title: '无符合的商品',
      description: '请调整您的商品上传规则后再试一次',
    },
  },
  deletePopup: {
    title: '删除产品目录',
    description:
      '删除后可能影响使用此产品目录投放的广告，商品将从媒体渠道中被删除',
    confirmDescription: '我理解刪除後將無法復原，我仍要刪除！',
  },
};

export default productFeedManager;
